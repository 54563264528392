import { graphql } from 'gatsby';
import React from 'react';
import CodingRunningVideoPoster from '../../../content/assets/images/educative/educative-code-running-poster.jpg';
import CodingRunningVideoMp4 from '../../../content/assets/images/educative/educative-code-running.mp4';
import CodingRunningVideoWebm from '../../../content/assets/images/educative/educative-code-running.webm';
import EducativeQuizVideoPoster from '../../../content/assets/images/educative/educative-quiz-poster.jpg';
import EducativeQuizVideoMp4 from '../../../content/assets/images/educative/educative-quiz.mp4';
import EducativeQuizVideoWebm from '../../../content/assets/images/educative/educative-quiz.webm';
import EducativeWalkthroughVideoPoster from '../../../content/assets/images/educative/educative-walkthrough-poster.jpg';
import EducativeWalkthroughVideoMp4 from '../../../content/assets/images/educative/educative-walkthrough.mp4';
import EducativeWalkthroughVideoWebm from '../../../content/assets/images/educative/educative-walkthrough.webm';
import EducativeJupyteVideoPoster from '../../../content/assets/images/educative/educative_jupyter-poster.jpg';
import EducativeJupyteVideoMp4 from '../../../content/assets/images/educative/educative_jupyter.mp4';
import EducativeJupyteVideoWebm from '../../../content/assets/images/educative/educative_jupyter.webm';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Accordion from '../../components/ui/Accordion/Accordion';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Cards/Block';
import Card from '../../components/ui/Cards/Card';
import Seo from '../../components/utils/Seo';
import { Accent, ButtonType, Direction } from '../../globals';
import './introduction-to-deep-learning-course.scss';

interface IntroductionToDeepLearningCourseProps {
    data: {
        introDlCourseYaml: {
            image: FluidImage;
            faq: QuestionAnswer[];
        };
    };
}

const IntroductionToDeepLearningCourse: React.FC<IntroductionToDeepLearningCourseProps> = ({
    data
}: IntroductionToDeepLearningCourseProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Introduction to Deep Learning Course"
                url="introduction-to-deep-learning-course/"
                description="Introduction to Deep Learning Interactive Course Landing Page"
                image={data.introDlCourseYaml.image.childImageSharp.fluid.src}
            />
            <div className="intro-dl-course-banner">
                <div className="intro-dl-course-banner__text">
                    <h1>Introduction to Deep Learning & Neural Networks</h1>
                </div>

                <div className="intro-dl-course-banner__text--secondary">
                    <h2>
                        An interactive text-based course to learn the principles
                        behind deep learning architectures. Explore the theory
                        and intuition behind the algorithms and build your
                        models with Pytorch.
                    </h2>
                </div>

                <div className="intro-dl-course-banner__button-container">
                    <Button
                        accent={Accent.primary}
                        type={ButtonType.linkExternal}
                        onClickLink="https://www.educative.io/courses/intro-deep-learning?aff=VEe5"
                        className="intro-dl-course-button__item--banner"
                    >
                        Enroll now
                    </Button>
                </div>
            </div>

            <PageSection className="intro-dl-course-section ">
                <Container>
                    <h2 className="intro-dl-course-section__text">
                        Learn Deep Learning
                    </h2>
                    <h3 className="intro-dl-course-section__text--branded">
                        with AI Summer
                    </h3>

                    <p className="intro-dl-course-section__text">
                        This course is an accumulation of well-grounded
                        knowledge and experience in deep learning. It provides
                        you with the basic concepts you need in order to start
                        working with and training various machine learning
                        models.
                    </p>
                    <p className="intro-dl-course-section__text">
                        You will cover both basic and intermediate concepts
                        including but not limited to: convolutional neural
                        networks, recurrent neural networks, generative
                        adversarial networks as well as transformers.
                    </p>
                    <p className="intro-dl-course-section__text">
                        After completing this course, you will have a
                        comprehensive understanding of the fundamental
                        architectural components of deep learning. Whether
                        you’re a data and computer scientist, computer and big
                        data engineer, solution architect, or software engineer,
                        you will benefit from this course.
                    </p>

                    <Group size={2}>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="rgba(85, 83, 255,1)"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Understand the most popular Deep Learning models
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="rgba(85, 83, 255,1)"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Get a solid grasp on the mathematics and the
                                intuition behind the algorithms
                            </h4>
                        </div>
                    </Group>

                    <Group size={2}>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="rgba(85, 83, 255,1)"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Gain a good experience with Deep Learning
                                programming and Pytorch
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 42.67"
                                    width="32"
                                    height="32"
                                    fill="rgba(85, 83, 255,1)"
                                >
                                    <g>
                                        <g>
                                            {/*eslint-disable-next-line max-len*/}
                                            <path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Learn using a hands-on approach and build your
                                models in the browser
                            </h4>
                        </div>
                    </Group>
                </Container>
            </PageSection>

            <PageSection className="intro-dl-course-section ">
                <Container>
                    <h2 className="intro-dl-course-section__text">
                        Understand the fundamental Deep Learning architectures.
                    </h2>
                    <div className="intro-dl-course-toc">
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                1
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Neural Networks</h3>
                                <p>
                                    Understand the basic principles behind
                                    Neural Networks, Gradient Descent and
                                    Pytorch.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                2
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Training Neural Networks</h3>
                                <p>
                                    Familiarize yourself with optimization
                                    algorithms, activation functions and train
                                    your first model.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                3
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Convolutional Neural Networks</h3>
                                <p>
                                    Learn about convolution, how it is used in
                                    convolutional networks and advanced concepts
                                    such a batch normalization and skip
                                    connections.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                4
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Recurrent Neural Networks</h3>
                                <p>
                                    Build RNNs and LSTMs from scratch and get to
                                    know how to use them for time series
                                    analysis and prediction.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                5
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Autoencoders</h3>
                                <p>
                                    Learn the theory and mathematics behind
                                    Autoencoders and Variational Autoencoders.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                6
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Generative Adversarial Networks</h3>
                                <p>
                                    Dive into adversarial attacks, GANs and
                                    explore how to train one using Pytorch.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                7
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Attention and Transformers</h3>
                                <p>
                                    Discover attention-based models, the
                                    Transformer architecture and how they
                                    revolutionalized the natural language
                                    processing field.
                                </p>
                            </div>
                        </div>
                        <div className="intro-dl-course-toc__lesson">
                            <div className="intro-dl-course-toc__lesson__number">
                                8
                            </div>
                            <div className="intro-dl-course-toc__lesson__text">
                                <h3>Graph Neural Networks</h3>
                                <p>
                                    Process and analyze graph data with Graph
                                    Neural Networks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="intro-dl-course-button__container">
                        <Button
                            accent={Accent.primary}
                            type={ButtonType.linkExternal}
                            onClickLink="https://www.educative.io/courses/intro-deep-learning?aff=VEe5"
                            className="intro-dl-course-button__item"
                        >
                            Enroll now
                        </Button>
                    </div>
                </Container>
            </PageSection>

            <PageSection className="intro-dl-course-section ">
                <Container>
                    <h2 className="intro-dl-course-section__text">
                        Learn the intuition and mathematics using a text-based
                        approach
                    </h2>
                    <p className="intro-dl-course-section__text">
                        Educative.io offers strictly interactive text-based
                        courses focusing on hands-on experience without the
                        hassle of videos. That way you speed up the learning
                        process, keep notes without pausing the video and access
                        anything fast.
                    </p>
                    <Card
                        className="intro-dl-course-card"
                        direction={Direction.vertical}
                    >
                        <video
                            className="intro-dl-course-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={EducativeWalkthroughVideoPoster}
                        >
                            <source
                                src={EducativeWalkthroughVideoMp4}
                                type="video/mp4"
                            />
                            <source
                                src={EducativeWalkthroughVideoWebm}
                                type="video/webm"
                            />
                            <p>
                                Your browser doesn't support HTML5 video. Here
                                is a{' '}
                                <a href={EducativeWalkthroughVideoMp4}>
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </Card>
                    <h2 className="intro-dl-course-section__text">
                        Develop your models using interactive code editors
                    </h2>
                    <p className="intro-dl-course-section__text">
                        You can quickly jump on coding using the provided live
                        coding environements and jupyter notebooks. No setup, no
                        installing dependencies. Program and execute your code
                        straight in your browser.
                    </p>
                    <Card
                        direction={Direction.vertical}
                        className="intro-dl-course-card"
                    >
                        <video
                            className="intro-dl-course-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={CodingRunningVideoPoster}
                        >
                            <source
                                src={CodingRunningVideoMp4}
                                type="video/mp4"
                            />
                            <source
                                src={CodingRunningVideoWebm}
                                type="video/webm"
                            />
                            <p>
                                Your browser doesn't support HTML5 video. Here
                                is a{' '}
                                <a href={CodingRunningVideoMp4}>
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </Card>
                    <h2 className="intro-dl-course-section__text">
                        Experiment with Jupyter notebooks
                    </h2>
                    <p className="intro-dl-course-section__text">
                        Build and train your models in Jupyter notebooks with
                        zero setup. Experiment with different hyperparameters,
                        optimization algorithms and architectures all in your
                        browser.
                    </p>
                    <Card
                        direction={Direction.vertical}
                        className="intro-dl-course-card"
                    >
                        <video
                            className="intro-dl-course-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={EducativeJupyteVideoPoster}
                        >
                            <source
                                src={EducativeJupyteVideoMp4}
                                type="video/mp4"
                            />
                            <source
                                src={EducativeJupyteVideoWebm}
                                type="video/webm"
                            />
                            <p>
                                Your browser doesn't support HTML5 video. Here
                                is a{' '}
                                <a href={EducativeJupyteVideoMp4}>
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </Card>

                    <h2 className="intro-dl-course-section__text">
                        Test your knowledge with coding challenges and quizzes
                    </h2>
                    <p className="intro-dl-course-section__text">
                        You can test your understanding using interactive
                        quizzes, easy leetcode-style coding challenges with
                        solutions. In the end, you will also need to pass a
                        final assesment finish the course.
                    </p>
                    <Card
                        direction={Direction.vertical}
                        className="intro-dl-course-card"
                    >
                        <video
                            className="intro-dl-course-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={EducativeQuizVideoPoster}
                        >
                            <source
                                src={EducativeQuizVideoMp4}
                                type="video/mp4"
                            />
                            <source
                                src={EducativeQuizVideoWebm}
                                type="video/webm"
                            />
                            <p>
                                Your browser doesn't support HTML5 video. Here
                                is a{' '}
                                <a href={EducativeQuizVideoMp4}>
                                    link to the video
                                </a>{' '}
                                instead.
                            </p>
                        </video>
                    </Card>
                </Container>
            </PageSection>
            <PageSection className="intro-dl-course-section--withbackground ">
                <Container>
                    <Block
                        direction={Direction.vertical}
                        className="intro-dl-course-section--withbackground__container"
                    >
                        <div className="intro-dl-course-button__container">
                            <h2 className="intro-dl-course-section__text--cta">
                                Get started with Deep Learning
                            </h2>
                            <Button
                                accent={Accent.secondary}
                                type={ButtonType.linkExternal}
                                onClickLink="https://www.educative.io/courses/intro-deep-learning?aff=VEe5"
                                className="intro-dl-course-button__item--secondary"
                            >
                                Enroll now
                            </Button>
                        </div>
                    </Block>
                </Container>
            </PageSection>

            <PageSection className="intro-dl-course-section ">
                <Container>
                    <h2 className="intro-dl-course-section__text">
                        But why 100% text and no videos?
                    </h2>
                    <p className="intro-dl-course-section__text">
                        We believe that text is more effective and faster for
                        learning advanced concepts such as Deep Learning.
                    </p>
                    <Group size={3}>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Text is faster than videos
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Learn at your own pace
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Active learning and hands-on experience
                            </h4>
                        </div>
                    </Group>
                    <Group size={3}>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Revisit lessons and skip others easily
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Keep notes without pausing the video
                            </h4>
                        </div>
                        <div className="intro-dl-course-section__bullet">
                            <span>
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 18 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-103.000000, -4323.000000)">
                                            <g transform="translate(100.000000, 4266.000000)">
                                                <g transform="translate(0.000000, 54.000000)">
                                                    <g>
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                            // eslint-disable-next-line max-len
                                                            d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z"
                                                            fill="rgba(85, 83, 255,1)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <h4 className="intro-dl-course-section__bullet__text">
                                Copy paste the code in your own environment
                            </h4>
                        </div>
                    </Group>

                    <h2 className="intro-dl-course-section__text">
                        How this course came into life?
                    </h2>

                    <p className="intro-dl-course-section__text">
                        For more than a year, we wrote weekly deep learning
                        articles for the AI Summer blog. One day we came across
                        educative.io and we had this awesome idea. To combine
                        some of them into a single resource and give you the
                        ability to learn Deep Learning by doing. That's why
                        {'  '}
                        <strong>
                            70% of the course's content can be found in our blog
                            for free
                        </strong>
                        . So if you can't afford this course, you are more than
                        welcome to read our free articles. If on the other hand
                        you want to support us for our efforts and learn using a
                        highly interactive platform, you can spend a few bucks.
                    </p>
                </Container>
            </PageSection>

            <PageSection className="intro-dl-course-faq-section">
                <Container>
                    <h2>FAQ</h2>
                    <Accordion items={data.introDlCourseYaml.faq} />
                    <div className="intro-dl-course-button__container">
                        <Button
                            accent={Accent.primary}
                            type={ButtonType.linkExternal}
                            onClickLink="https://www.educative.io/courses/intro-deep-learning?aff=VEe5"
                            className="intro-dl-course-button__item"
                        >
                            Enroll now
                        </Button>
                    </div>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const IntroductionToDeepLearningCourseQuery = graphql`
    {
        introDlCourseYaml {
            faq {
                question
                answer
            }
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`;

export default IntroductionToDeepLearningCourse;
